import React, { useEffect } from 'react';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  TextField,
  Checkbox,
  Link,
  PrimaryButton,
  Button,
  Spinner,
} from 'office-ui-fabric-react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from 'office-ui-fabric-react/lib/Stack';
import './Home.css';
import { loadTheme } from '@fluentui/react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

import TevolveGame from './modules/TevolveGame'
import { Query } from './modules/Query'
import { NDA } from './modules/NDA'
import {JitsiMeeting} from '@jitsi/web-sdk'
initializeIcons(/* optional base url */);
loadTheme({
  palette: {
    neutralLighterAlt: '#282828',
    neutralLighter: '#313131',
    neutralLight: '#3f3f3f',
    neutralQuaternaryAlt: '#484848',
    neutralQuaternary: '#4f4f4f',
    neutralTertiaryAlt: '#6d6d6d',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#1f1f1f',
    themePrimary: '#3a96dd',
    themeLighterAlt: '#020609',
    themeLighter: '#091823',
    themeLight: '#112d43',
    themeTertiary: '#235a85',
    themeSecondary: '#3385c3',
    themeDarkAlt: '#4ba0e1',
    themeDark: '#65aee6',
    themeDarker: '#8ac2ec',
    accent: '#3a96dd'
  },
});

const cancelIcon = { iconName: 'Cancel' };


export class Home extends React.Component {
  constructor() {
    super()

   

    //Redirect to https
    //if(window.location.protocol == 'http:') {
    //  console.log("Redirecting to https");
    //  window.location.href = window.location.href.replace('http:', 'https:')
    //}

    this.state = {
      showLoginMenu: true,
      showLoginFailedText: false,
      showvoiceNameBox: false,
      login: false,
      isLoading: false,
      usernameInput: '',
      gameCodeInput: '',
      ndaAcceptedInput: false,
      gamemode: '',
      build: '',
      voiceName: '',
      ndaOpen: false,
      name: '',
      gameCode: '',
      showActualVoiceBox: false
    }
  }

  //Login callback fields
  onUsernameInputChanged(event) { this.setState({ usernameInput: event.target.value }) }
  onGameCodeInputChanged(event) { this.setState({ gameCodeInput: event.target.value }) }
  onNDACheckboxChanged(event) { this.setState({ ndaAcceptedInput: event.target.checked }) }
  onVoiceNameChange(event){this.setState({voiceName:event.target.value})}

  //Checks if the user has inputed the required fields to login
  isLoginFormReady() {
    return !this.state.isLoading && this.state.usernameInput && this.state.gameCodeInput && this.state.ndaAcceptedInput
  }
  async onClickLogin() {
    this.setState({ isLoading: true })

    //Show login spinner
    var spinnerLogin = document.getElementById("spinnerLogin")
    spinnerLogin.style.display = "block"

    //Ask server to login with input username and game code. Will return true if succesful, else false if login was incorrect.
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/check-ticket?username=${this.state.usernameInput}&gameCode=${this.state.gameCodeInput}`)
    const content = await responce.json();
    const loginStatus = Boolean(content)

    //Check if login was successful
    if(loginStatus === false) {
      //Failed to login

      this.setState({
        showLoginFailedText: true
      })

      //Show failed to login text
      var failedText = document.getElementById("loginFailedText")
      failedText.style.display = "block"
    } else {
      //Login was successful

      
      //Get arguments from url
      const queryParams = new URLSearchParams(window.location.search)

      const gamemode = queryParams.get('gamemode') || "player"
      console.log("Gamemode: " + gamemode)

      const build = queryParams.get('build') || "release/339"
     
 
      this.setState({
        showLoginMenu: false, 
        showvoiceNameBox: true,
        name: `${this.state.usernameInput}`,
        gameCode: this.state.gameCodeInput,
        gamemode: gamemode,
        build: build,
      })
    }
    //Hide login spinner
    spinnerLogin.style.display = "none"

    this.setState({ isLoading: false })
  }


  

  async onOpenUnity()
  {
    this.setState({login: true, showvoiceNameBox: false, showActualVoiceBox: true})
  }

  openNDA(open) {
    this.setState({
      ndaOpen: open
    })
  }

  render() {
    const isModalOpen = true;


    
    const openNDA = this.openNDA.bind(this, true)
    function _renderNDALabel() {
      return (
        <span>
          I accept the{' '}
          <Link onClick={openNDA} target="_blank">
            NDA.
          </Link>
        </span>
      );
    }

    const itemAlignmentsStackTokens = {
      childrenGap: 5,
      padding: 10,
    };

    return (
      <div className="background">
        {/* Login menu /*/}
        <Modal
          isOpen={this.state.showLoginMenu}
          isBlocking={true}
          containerClassName={contentStyles.container}
          dragOptions={undefined}
        >
          <div className={contentStyles.header}>
            <span id="titleId">Welcome to Tevolve</span>
          </div>
          <div className={contentStyles.body}>
            <p>
              Welcome to Tevolve! Here you can enter your first name and last name. The game will load after pressing the "Play Game" button.
            </p>
            <p id="loginFailedText" >
              Wrong username or game code!
            </p>
            <Stack tokens={itemAlignmentsStackTokens}>
              <TextField label="Username" required onChange={this.onUsernameInputChanged.bind(this)}/>
              <TextField label="Game Code" required onChange={this.onGameCodeInputChanged.bind(this)}/>
              <Stack.Item align="end">
                <Checkbox label="I accept the NDA" onRenderLabel={_renderNDALabel}  onChange={this.onNDACheckboxChanged.bind(this)}/>
              </Stack.Item>
              <Stack.Item align="end">
                <PrimaryButton text="Login" onClick={this.onClickLogin.bind(this)} allowDisabledFocus disabled={!this.isLoginFormReady.bind(this)()}>
                  <Spinner id="spinnerLogin" as="span" animation="border" role="status"/>
                </PrimaryButton>
              </Stack.Item>
            </Stack>
          </div>
        </Modal>

    {/* Voice Name /*/}
  <Modal
          isOpen={this.state.showvoiceNameBox}
          isBlocking={false}
          containerClassName={contentStyles.container}
          dragOptions={undefined}
        >
          <div className={contentStyles.header}>
            <span id="titleId">Please Enter a nickname</span>
          </div>
          <div className={contentStyles.body}>
          
          
            <Stack tokens={itemAlignmentsStackTokens}>
              <TextField label="Nickname" required onChange={this.onVoiceNameChange.bind(this)}/>
        
              <Stack.Item align="end">
                <PrimaryButton text="Login" onClick={this.onOpenUnity.bind(this)} allowDisabledFocus disabled={!this.isLoginFormReady.bind(this)()}>
                  <Spinner id="spinnerLogin" as="span" animation="border" role="status"/>
                </PrimaryButton>
              </Stack.Item>
            </Stack>
          </div>
        </Modal>


        {/* NDA popup /*/}
        <Modal
          isOpen={this.state.ndaOpen}
          isBlocking={true}
          containerClassName={contentStyles.ndacontainer}
          dragOptions={undefined}
        >
          <div className={contentStyles.header}>
            <span id="titleId">NDA</span>
            <Button
              text="Close NDA" 
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={this.openNDA.bind(this, false)}
            />
          </div>
          <div className={contentStyles.body}>
            <NDA/>
          </div>
        </Modal>

        {/* Unity game /*/}
        {
          this.state.login ? <Query>
          {(query) => <TevolveGame
              build={this.state.build}
              gamemode={this.state.gamemode}
              name={this.state.name}
              gameCode={this.state.gameCode}
              acceptedNDA={this.state.ndaAcceptedInput}
              voiceName={this.state.voiceName}>
            </TevolveGame>}
          </Query> : null
        }


    
      </div>
    );
  }
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    maxWidth: '500px'
  },
  voiceContainer:
  {
    display: 'flex',
    maxWidth: '1000px',
    backgroundColor: 'rgba(0, 0, 0, 0) !important'  
  },
  ndacontainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  header: [
    // tslint:disable-next-line:deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const toggleStyles = { root: { marginBottom: '20px',overflow:'hidden' } };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default Home;
