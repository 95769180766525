import { FunctionComponent } from 'react';
import { useLocation } from "react-router-dom";


function useQuery() {
    return new URLSearchParams(useLocation().search);
  }


export const Query = ({
  children,
}) => {
  const query = useQuery();

  return children(query);
};