import React, { Component } from 'react';
import { Document, Page } from 'react-pdf';
import { DefaultButton, PrimaryButton, Stack, IStackTokens } from 'office-ui-fabric-react';

const stackTokens = { childrenGap: 10 };

export class NDA extends Component {
  state = {
    numPages: null,
    pageNumber: 1,
  }

  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  }

  nextPage(previous) {
    this.setState({ pageNumber: this.state.pageNumber + (1 * previous)});
  }

  render() {
    const { pageNumber, numPages } = this.state;

    return (
      <div>
        <Document
          file="Tester_NDA_3272020.pdf"
          onLoadSuccess={this.onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Stack horizontal tokens={stackTokens}>
          <DefaultButton text="Previous page" onClick={this.nextPage.bind(this, -1)} allowDisabledFocus disabled={this.state.pageNumber === 1}/>
          <p>
            Page {pageNumber} of {numPages}
          </p>
          <PrimaryButton text="Next page" onClick={this.nextPage.bind(this, 1)} allowDisabledFocus disabled={this.state.pageNumber === this.state.numPages}/>
        </Stack>
      </div>
    );
  }
}