import React, { useState, useEffect } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";

import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import './TevolveGame.css';
import { PixelPerfectHome } from '../PixelPerfectHome'
import { JitsiMeeting } from '@jitsi/web-sdk';
import { BenchmarkHome } from '..';

let voiceRoom = "tevolve-residential-aosuid"

class BenchmarkGame extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isToggleOn:false,
      progression: 0,
      loading: true,
      onWidth: '20%',
      offWidth: '0%',
      currentWidth: '20%',
      gameWidth: '80%'
    }
    const unityContext = new UnityContext({
      loaderUrl: 'https://builds.tevolve.net/' + 'release/Benchmark' + '/Build/Tevolve.loader.js',
      dataUrl: 'https://builds.tevolve.net/' + 'release/Benchmark' + '/Build/Tevolve.data',
      frameworkUrl: 'https://builds.tevolve.net/' + 'release/Benchmark' + '/Build/Tevolve.framework.js',
      codeUrl: 'https://builds.tevolve.net/' + 'release/Benchmark' + '/Build/Tevolve.wasm',
    });
    
    window.unityContent = unityContext
    this.unity = unityContext


    function App() {
     
      return (
        <Unity unityContext={unityContext} />
      )
    }

   

    unityContext.on("progress", progression => {
      this.setState({
        progression: progression
      });
    });
    App();
  }



  render() {
    return (
      <div className="game" style={{ display: 'flex', height: "100vh" }}>
        <Unity unityContext={this.unity} style={{  width: "100%", height: "100%"}} />
        {
          this.state.progression === 1 ? null : <span className="loadingIndicator">
            <ProgressIndicator label="Loading Benchmark. Please Wait." description={(this.state.progression * 100).toFixed(1) + "% complete..."} percentComplete={this.state.progression} />
          </span>
        }
      </div>
    )
  }
}


export default BenchmarkGame;
