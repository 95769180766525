import React from 'react';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  TextField,
  Checkbox,
  Link,
  PrimaryButton,
  Button,
  Spinner,
} from 'office-ui-fabric-react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from 'office-ui-fabric-react/lib/Stack';
import './Home.css';
import { loadTheme } from '@fluentui/react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';


import { Query } from './modules/Query'
import { NDA } from './modules/NDA'
import PixelPerfectGame from './modules/PixelPerfectGame';
import BenchmarkGame from './modules/BenchmarkGame';

initializeIcons(/* optional base url */);
loadTheme({
  palette: {
    neutralLighterAlt: '#282828',
    neutralLighter: '#313131',
    neutralLight: '#3f3f3f',
    neutralQuaternaryAlt: '#484848',
    neutralQuaternary: '#4f4f4f',
    neutralTertiaryAlt: '#6d6d6d',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#1f1f1f',
    themePrimary: '#3a96dd',
    themeLighterAlt: '#020609',
    themeLighter: '#091823',
    themeLight: '#112d43',
    themeTertiary: '#235a85',
    themeSecondary: '#3385c3',
    themeDarkAlt: '#4ba0e1',
    themeDark: '#65aee6',
    themeDarker: '#8ac2ec',
    accent: '#3a96dd'
  },
});

const cancelIcon = { iconName: 'Cancel' };

var testing ={
  buildPath: "",
  gameCodeTest: ""
}

export{testing}

export class PixelPerfectHome extends React.Component {
  constructor() {
    super()

    //Redirect to https
    //if(window.location.protocol == 'http:') {
    //  console.log("Redirecting to https");
    //  window.location.href = window.location.href.replace('http:', 'https:')
    //}



  
    this.state = {
      showLoginMenu: true,
      showLoginFailedText: false,
      showvoiceNameBox: false,
      login: false,
      isLoading: false,
      usernameInput: '',
      gameCodeInput: '',
      ndaAcceptedInput: false,
      gamemode: '',
      build: '',
      voiceName: '',
      ndaOpen: false,
      name: '',
      gameCode: '',
      showActualVoiceBox: false,
      gameName: ''
    }
  }

  //Login callback fields
  onUsernameInputChanged(event) { this.setState({ usernameInput: event.target.value }) }
  onGameCodeInputChanged(event) { this.setState({ gameCodeInput: event.target.value }) }
  onNDACheckboxChanged(event) { this.setState({ ndaAcceptedInput: event.target.checked }) }
  onVoiceNameChange(event){this.setState({voiceName:event.target.value})}
  
  //Checks if the user has inputed the required fields to login
  isLoginFormReady() {
    return !this.state.isLoading && this.state.usernameInput && this.state.gameCodeInput && this.state.ndaAcceptedInput
  }
  async onClickLogin() {
    this.setState({ isLoading: true })

    this.state.showLoginMenu = false;

    //Show login spinner
    var spinnerLogin = document.getElementById("spinnerLogin")
    spinnerLogin.style.display = "block"
      const build = "release/Benchmark"
      console.log(build)

    
    //Hide login spinner
    spinnerLogin.style.display = "none"

    this.setState({ isLoading: false })

    this.state.login = true;
  }
  


  openNDA(open) {
    this.setState({
      ndaOpen: open
    })
  }

  render() {
    const isModalOpen = true;

    const itemAlignmentsStackTokens = {
      childrenGap: 5,
      padding: 10,
    };

    return (
      <div className="background">
        {/* Login menu /*/}
        <Modal
          isOpen={this.state.showLoginMenu}
          isBlocking={true}
          containerClassName={contentStyles.container}
          dragOptions={undefined}
        >
          <div className={contentStyles.header}>
            <span id="titleId">Welcome to Pixel Perfect's Benchmark!</span>
          </div>
          <div className={contentStyles.body}>
            <p>
              Here is the Benchmark for Pixel-Perfect. We suggest that you run this, before you buy a pass to play the game. To make sure your computer can run a full 3D environment in the Browser!
              Please note that the benchmark might take 1-2 minutes to load.
            </p>
            <Stack tokens={itemAlignmentsStackTokens}>
             
              <Stack.Item align="end">
                <PrimaryButton text="Open Benchmark" onClick={this.onClickLogin.bind(this)}>
                  <Spinner id="spinnerLogin" as="span" animation="border" role="status"/>
                </PrimaryButton>
              </Stack.Item>
            </Stack>
          </div>
        </Modal>
       
        {/* Unity game /*/}
        {
          this.state.login ? <Query>
          {(query) => <BenchmarkGame
              build={this.state.build}
              gamemode={this.state.gamemode}
              name={this.state.name}
              gameCode={this.state.gameCode}
              gameName = {this.state.gameName}
              acceptedNDA={this.state.ndaAcceptedInput}
              voiceName = {this.state.voiceName}>
            </BenchmarkGame>}
          </Query> : null
        }
      </div>
    );
  }
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    maxWidth: '500px'
  },
  ndacontainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  header: [
    // tslint:disable-next-line:deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default PixelPerfectHome;
