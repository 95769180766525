import React from 'react';
import Unity, { UnityContent } from "react-unity-7";
import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import './TevolveGame.css';
import { JitsiMeeting } from '@jitsi/web-sdk';

let voiceRoom = "tevolve-corporate-aojdasd"
class TevolveGame extends React.Component {


  constructor(props) {
    super(props)

    this.state = {
      isToggleOn:false,
      progression: 0,
      loading: true,
      onWidth: '20%',
      offWidth: '0%',
      currentWidth: '20%',
      gameWidth: '80%'
    }

    this.handleClick = this.handleClick.bind(this);



    this.unityContent = new UnityContent(
      `https://builds.tevolve.net/${props.build}/Build/Tevolve.json`,
      `https://builds.tevolve.net/${props.build}/Build/UnityLoader.js`, {

    }
    );




    voiceRoom += this.props.gameCode

    window.unityContent = this.unityContent;

    console.log(this.unityContent);

    this.unityContent.on("progress", progression => {
      this.setState({
        progression: progression
      });
    });

    this.unityContent.on("loaded", () => {
      if (this.props.gamemode === "player") {
        this.unityContent.send(
          "Loader",
          "PreventLoad"
        )
      }
      if (this.props.gamemode === "player") {
        this.unityContent.send(
          "Loader",
          "JoinGame",
          JSON.stringify({
            name: this.props.name,
            gameCode: this.props.gameCode
          })
        )
        this.setState({
          joined: true
        })
      }
    })
  }


  componentDidUpdate() {
    //if(this.props.acceptedNDA && !this.state.loading) {
    //  this.loadGame()
    //}
    // console.log(this.props)
    // this.unityContent.on("loaded", () => {
    //   if(!this.state.joined) {
    //     if(this.props.username && this.props.gameCode) {
    //       if(this.props.gamemode == "player") {
    //         this.unityContent.send(
    //           "Loader",
    //           "JoinGame",
    //           this.props.name,
    //           this.props.gameCode
    //         )
    //       }
    //     }
    //   }
    // });
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      currentWidth: this.state.isToggleOn ? this.state.onWidth : this.state.offWidth,
      gameWidth: this.state.isToggleOn ? '80%' : '100%'
    }));
  }


  render() {
    return (



      <div className="game" style={{ display: 'flex', height: '100vh' }}>
        <Unity unityContent={this.unityContent} style={{ width: this.state.gameWidth, height: "100vh" }} />
        {
          this.state.progression === 1 ? null : <span className="loadingIndicator">
            <ProgressIndicator label="Loading game content" description={(this.state.progression * 100).toFixed(1) + "% complete..."} percentComplete={this.state.progression} />
          </span>
        }

        {/* { <div id="test" style={{ width: this.state.gameWidth, height: "100vh", display:'grid' }}>
          
       
        </div>} */}
     
        <div id="jitsi-meeting" style={{ width: this.state.currentWidth, height: "100vh", userSelect: "none"}}>
          { <JitsiMeeting
            domain="meet.jit.si"
            roomName={voiceRoom}
            configOverwrite={{
              startWithAudioMuted: false,
              disableModeratorIndicator: true,
              startScreenSharing: false,
              enableEmailInStats: false,
              startAudioOnly: true,
              enableWelcomePage: false,
              prejoinPageEnabled: false,
              outerWidth: 1200,
              toolbarButtons:
                [
                  'camera',
                  'toggle-camera',
                  'microphone',
                  'hangup'
                ]

            }}

            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              DISABLE_VIDEO_BACKGROUND: false,
            }}
            userInfo={{
              displayName: this.props.voiceName
            }}
            onApiReady={(externalApi) => {
              // here you can attach custom event listeners to the Jitsi Meet External API
              // you can also store it locally to execute commands
            }}
          />}
        </div>
        <button onClick={this.handleClick} style={{ alignSelf:'flex-start',justifySelf:'flex-end', backgroundColor: 'rgba(0,0,0,0);', border: 'none',userSelect:'none'}}>

<span style={{fontSize:'50px',color:'black' }}>
  {this.state.isToggleOn ? '<' : '>'}
</span>
</button>

      </div>
    )
  }
}




export default TevolveGame;
