import React from 'react';
import {
  getTheme,
  mergeStyleSets,
  FontWeights,
  Modal,
  TextField,
  Checkbox,
  Link,
  PrimaryButton,
  Button,
  Spinner,
  Dropdown,
  Separator,
} from 'office-ui-fabric-react';
import { Stack, IStackStyles, IStackTokens, IStackItemStyles } from 'office-ui-fabric-react/lib/Stack';
import './Admin.css';
import { loadTheme } from '@fluentui/react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';

initializeIcons(/* optional base url */);
loadTheme({
  palette: {
    neutralLighterAlt: '#282828',
    neutralLighter: '#313131',
    neutralLight: '#3f3f3f',
    neutralQuaternaryAlt: '#484848',
    neutralQuaternary: '#4f4f4f',
    neutralTertiaryAlt: '#6d6d6d',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#1f1f1f',
    themePrimary: '#3a96dd',
    themeLighterAlt: '#020609',
    themeLighter: '#091823',
    themeLight: '#112d43',
    themeTertiary: '#235a85',
    themeSecondary: '#3385c3',
    themeDarkAlt: '#4ba0e1',
    themeDark: '#65aee6',
    themeDarker: '#8ac2ec',
    accent: '#3a96dd'
  },
});

const cancelIcon = { iconName: 'Cancel' };

export class Admin extends React.Component {
  constructor() {
    super()

    this.state = {
      showLoginMenu: true,
      showLoginFailedText: false,

      login: false,
      isLoading: false,
      
      adminUsernameInput: '',
      adminPasswordInput: '',

      addTicketFirstNameInput: '',
      addTicketLastNameInput: '',
      addTicketUsernameInput: '',
      addTicketGameCodeInput: '',
      addTicketStartTimeInput: '',
      addTicketEndTimeInput: '',
      addTicketselectedGame: '',
      updateTicketCurrentUsernameInput: '',
      updateTicketCurrentGameCodeInput: '',
      updateTicketFirstNameInput: '',
      updateTicketLastNameInput: '',
      updateTicketUsernameInput: '',
      updateTicketGameCodeInput: '',
      updateTicketStartTimeInput: '',
      updateTicketEndTimeInput: '',
      updateTicketselectedGame: '',
      deleteTicketUsernameInput: '',
      deleteTicketGameCodeInput: '',
      addAdminUsernameInput: '',
      addAdminPasswordInput: '',
      updateAdminCurrentUsernameInput: '',
      updateAdminCurrentPasswordInput: '',
      updateAdminPasswordInput: '',
      deleteAdminUsernameInput: '',
      deleteAdminPaswordInput: '',

      outputLogText: '',

      showActionAddTicket: true,
      showActionUpdateTicket: false,
      showActionDeleteTicket: false,
    }
  }

  //Admin login callback fields
  onAdminUsernameInputChanged(event) { this.setState({ adminUsernameInput: event.target.value }) }
  onAdminPasswordInputChanged(event) { this.setState({ adminPasswordInput: event.target.value }) }

  //Add ticket callback fields
  onAddTicketFirstNameInputChanged(event) { this.setState({ addTicketFirstNameInput: event.target.value }) }
  onAddTicketLastNameInputChanged(event) { this.setState({ addTicketLastNameInput: event.target.value }) }
  onAddTicketUsernameInputChanged(event) { this.setState({ addTicketUsernameInput: event.target.value }) }
  onAddTicketGameCodeInputChanged(event) { this.setState({ addTicketGameCodeInput: event.target.value }) }
  onAddTicketStartTimeInputChanged(event) { this.setState({ addTicketStartTimeInput: event.target.value }) }
  onAddTicketEndTimeInputChanged(event) { this.setState({ addTicketEndTimeInput: event.target.value }) }

  //Update ticket callback fields
  onUpdateTicketCurrentUsernameInputChanged(event) { this.setState({ updateTicketCurrentUsernameInput: event.target.value }) }
  onUpdateTicketCurrentGameCodeInputChanged(event) { this.setState({ updateTicketCurrentGameCodeInput: event.target.value }) }
  onUpdateTicketFirstNameInputChanged(event) { this.setState({ updateTicketFirstNameInput: event.target.value }) }
  onUpdateTicketLastNameInputChanged(event) { this.setState({ updateTicketLastNameInput: event.target.value }) }
  onUpdateTicketUsernameInputChanged(event) { this.setState({ updateTicketUsernameInput: event.target.value }) }
  onUpdateTicketGameCodeInputChanged(event) { this.setState({ updateTicketGameCodeInput: event.target.value }) }
  onUpdateTicketStartTimeInputChanged(event) { this.setState({ updateTicketStartTimeInput: event.target.value }) }
  onUpdateTicketEndTimeInputChanged(event) { this.setState({ updateTicketEndTimeInput: event.target.value }) }

  //Delete ticket callback fields
  onDeleteTicketUsernameInputChanged(event) { this.setState({ deleteTicketUsernameInput: event.target.value }) }
  onDeleteTicketGameCodeInputChanged(event) { this.setState({ deleteTicketGameCodeInput: event.target.value }) }

  //Add admin callback fields
  onAddAdminUsernameInputChanged(event) { this.setState({ addAdminUsernameInput: event.target.value }) }
  onAddAdminPasswordInputChanged(event) { this.setState({ addAdminPasswordInput: event.target.value }) }

  //Update admin callback fields
  onUpdateAdminCurrentUsernameInputChanged(event) { this.setState({ updateAdminCurrentUsernameInput: event.target.value }) }
  onUpdateAdminCurrentPasswordInputChanged(event) { this.setState({ updateAdminCurrentPasswordInput: event.target.value }) }
  onUpdateAdminPasswordInputChanged(event) { this.setState({ updateAdminPasswordInput: event.target.value }) }

  //Delete admin callback fields
  onDeleteAdminUsernameInputChanged(event) { this.setState({ deleteAdminUsernameInput: event.target.value }) }
  onDeleteAdminPasswordInputChanged(event) { this.setState({ deleteAdminPasswordInput: event.target.value }) }

  onDropdown(e, selectedOption) {
    var actionAddTicket = document.getElementById("actionAddTicket")
    var actionUpdateTicket = document.getElementById("actionUpdateTicket")
    var actionDeleteTicket = document.getElementById("actionDeleteTicket")
    var actionAddAdmin = document.getElementById("actionAddAdmin")
    var actionUpdateAdmin = document.getElementById("actionUpdateAdmin")
    var actionDeleteAdmin = document.getElementById("actionDeleteAdmin")

    actionAddTicket.style.display = "none"
    actionUpdateTicket.style.display = "none"
    actionDeleteTicket.style.display = "none"
    actionAddAdmin.style.display = "none"
    actionUpdateAdmin.style.display = "none"
    actionDeleteAdmin.style.display = "none"

    this.setState({
      outputLogText: '',
    })

    switch(selectedOption.key) {
      case "add-ticket":
        actionAddTicket.style.display = "block"
        break;
      case "update-ticket":
        actionUpdateTicket.style.display = "block"
        break;
      case "delete-ticket":
        actionDeleteTicket.style.display = "block"
        break;
      case "add-admin":
        actionAddAdmin.style.display = "block"
        break;
      case "update-admin":
        actionUpdateAdmin.style.display = "block"
        break;
      case "delete-admin":
        actionDeleteAdmin.style.display = "block"
        break;
    }
  }

  onGameDropdown(e,selectedOption)
  {
    switch(selectedOption.key)
    {
      case "agency-secrets":
      console.log("Selected Agency Secrets")
      this.state.addTicketselectedGame = "Agency Secrets"
      break;
      case "game-of-clones":
        console.log("Selected Game Of Clones")
        this.state.addTicketselectedGame = "Game of Clones"
        break;
    }
  }

  //Login functions
  isLoginFormReady() {
    return this.state.adminUsernameInput && this.state.adminPasswordInput
  }
  async onClickLogin() {
    //Show spinner
    this.setState({ isLoading: true })
    var spinner = document.getElementById("spinnerLogin")
    spinner.style.display = "block"

    //Todo: Add special admin login POST CURL here
    //this.setState({
    //  showLoginMenu: false,
    //  showAdminConsoleMenu: true,
    //})

    //Ask server to login with input username and password. Will return true if succesful, else false if login was incorrect.
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/check-admin?username=${this.state.adminUsernameInput}&password=${this.state.adminPasswordInput}`)
    const content = await responce.json();
    const loginStatus = Boolean(content)

    //Check if login was successful
    if(loginStatus === false) {
      //Failed to logine

      this.setState({
        showLoginFailedText: true
      })

      //Show failed to login text
      var failedText = document.getElementById("loginFailedText")
      failedText.style.display = "block"
    } else {
      //Login was successful

      this.setState({
        showLoginMenu: false,
        login: true,
      })
    }

    //Hide spinner
    spinner.style.display = "none"
    this.setState({ isLoading: false })
  }

  //Add ticket functions
  isAddTicketFormReady() {
    return !this.state.isLoading && this.state.addTicketFirstNameInput && this.state.addTicketLastNameInput && this.state.addTicketUsernameInput && this.state.addTicketGameCodeInput && this.state.addTicketStartTimeInput && this.state.addTicketEndTimeInput
  }
  async onClickAddTicket(event) {
    //Show spinner
    this.setState({ isLoading: true })
    var spinner = document.getElementById("spinnerAddTicket")
    spinner.style.display = "block"

    //Ask server to add ticket
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/add-ticket?firstName=${this.state.addTicketFirstNameInput}&lastName=${this.state.addTicketLastNameInput}&username=${this.state.addTicketUsernameInput}&gameCode=${this.state.addTicketGameCodeInput}&startTime=${this.state.addTicketStartTimeInput}&endTime=${this.state.addTicketEndTimeInput}&gameName=${this.state.addTicketselectedGame}`)
    const content = await responce.json();

    //Print output
    console.log(content)
    this.setState({ outputLogText: JSON.stringify(content, undefined, 2) })

    //Hide spinner
    spinner.style.display = "none"
    this.setState({ isLoading: false })
  }

  //Update ticket functions
  isUpdateTicketFormReady() {
    return !this.state.isLoading && this.state.updateTicketCurrentUsernameInput && this.state.updateTicketCurrentGameCodeInput && this.state.updateTicketFirstNameInput && this.state.updateTicketLastNameInput && this.state.updateTicketUsernameInput && this.state.updateTicketGameCodeInput && this.state.updateTicketStartTimeInput && this.state.updateTicketEndTimeInput
  }
  async onClickUpdateTicket(event) {
    //Show spinner
    this.setState({ isLoading: true })
    var spinner = document.getElementById("spinnerUpdateTicket")
    spinner.style.display = "block"

    //Ask server to update ticket
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/update-ticket?username=${this.state.updateTicketCurrentUsernameInput}&gameCode=${this.state.updateTicketCurrentGameCodeInput}&newFirstName=${this.state.updateTicketFirstNameInput}&newLastName=${this.state.updateTicketLastNameInput}&newUsername=${this.state.updateTicketUsernameInput}&newGameCode=${this.state.updateTicketGameCodeInput}&newStartTime=${this.state.updateTicketStartTimeInput}&newEndTime=${this.state.updateTicketEndTimeInput}`)
    const content = await responce.json();

    //Print output
    console.log(content[0])
    if(content[0] === 0)
      this.setState({ outputLogText: "User not found." })
    else if(content[0] >= 1)
      this.setState({ outputLogText: "User updated." })

    //Hide spinner
    spinner.style.display = "none"
    this.setState({ isLoading: false })
  }

  //Delete ticket functions
  isDeleteTicketFormReady() {
    return !this.state.isLoading && this.state.deleteTicketUsernameInput && this.state.deleteTicketGameCodeInput
  }
  async onClickDeleteTicket(event) {
    //Show spinner
    this.setState({ isLoading: true })
    var spinner = document.getElementById("spinnerDeleteTicket")
    spinner.style.display = "block"

    //Ask server to delete ticket
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/delete-ticket?username=${this.state.deleteTicketUsernameInput}&gameCode=${this.state.deleteTicketGameCodeInput}`)
    const content = await responce.json();

    //Print output
    console.log(content)
    if(content === 0)
      this.setState({ outputLogText: "User not found." })
    else if(content === 1)
      this.setState({ outputLogText: "User was deleted." })

    //Hide spinner
    spinner.style.display = "none"
    this.setState({ isLoading: false })
  }

  //Add admin functions
  isAddAdminFormReady() {
    return !this.state.isLoading && this.state.addAdminUsernameInput && this.state.addAdminPasswordInput
  }
  async onClickAddAdmin(event) {
    //Show spinner
    this.setState({ isLoading: true })
    var spinner = document.getElementById("spinnerAddAdmin")
    spinner.style.display = "block"

    //Ask server to add ticket
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/add-admin?username=${this.state.addAdminUsernameInput}&password=${this.state.addAdminPasswordInput}`)
    const content = await responce.json();

    //Print output
    console.log(content)
    this.setState({ outputLogText: JSON.stringify(content, undefined, 2) })

    //Hide spinner
    spinner.style.display = "none"
    this.setState({ isLoading: false })
  }

  //Update admin functions
  isUpdateAdminFormReady() {
    return !this.state.isLoading && this.state.updateAdminCurrentUsernameInput && this.state.updateAdminCurrentPasswordInput && this.state.updateAdminPasswordInput
  }
  async onClickUpdateAdmin(event) {
    //Show spinner
    this.setState({ isLoading: true })
    var spinner = document.getElementById("spinnerUpdateAdmin")
    spinner.style.display = "block"

    //Ask server to update ticket
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/update-admin?username=${this.state.updateAdminCurrentUsernameInput}&oldPassword=${this.state.updateAdminCurrentPasswordInput}&newPassword=${this.state.updateAdminPasswordInput}`)
    const content = await responce.json();

    //Print output
    console.log(content)
    if(content === false)
      this.setState({ outputLogText: "User not found." })
    else if(content === true)
      this.setState({ outputLogText: "User updated." })

    //Hide spinner
    spinner.style.display = "none"
    this.setState({ isLoading: false })
  }

  //Delete admin functions
  isDeleteAdminFormReady() {
    return !this.state.isLoading && this.state.deleteAdminUsernameInput && this.state.deleteAdminPasswordInput
  }
  async onClickDeleteAdmin(event) {
    //Show spinner
    this.setState({ isLoading: true })
    var spinner = document.getElementById("spinnerDeleteAdmin")
    spinner.style.display = "block"

    //Ask server to delete ticket
    const responce = await fetch(`https://us-central1-tevolve.cloudfunctions.net/delete-admin?username=${this.state.deleteAdminUsernameInput}&password=${this.state.deleteAdminPasswordInput}`)
    const content = await responce.json();

    //Print output
    console.log(content)
    if(content === false)
      this.setState({ outputLogText: "User not found." })
    else if(content === true)
      this.setState({ outputLogText: "User was deleted." })

    //Hide spinner
    spinner.style.display = "none"
    this.setState({ isLoading: false })
  }

  render() {
    const isModalOpen = true;

    const itemAlignmentsStackTokens = {
      childrenGap: 5,
      padding: 10,
    };

    return (
      <div className="background">
        {/* Login menu /*/}
        <Modal
          isOpen={this.state.showLoginMenu}
          isBlocking={true}
          containerClassName={contentStyles.container}
          dragOptions={undefined}
        >
          <div className={contentStyles.header}>
            <span id="titleId">Admin Login</span>
          </div>
          <div className={contentStyles.body}>
            <p id="loginFailedText" >
              Wrong username or game code!
            </p>
            <Stack tokens={itemAlignmentsStackTokens}>
              <TextField label="Username" required onChange={this.onAdminUsernameInputChanged.bind(this)}/>
              <TextField label="Password" required onChange={this.onAdminPasswordInputChanged.bind(this)}/>
              <Stack.Item align="end">
                <PrimaryButton text="Login" onClick={this.onClickLogin.bind(this)} allowDisabledFocus disabled={!this.isLoginFormReady.bind(this)()}>
                  <Spinner id="spinnerLogin" as="span" animation="border" role="status"/>
                </PrimaryButton>
              </Stack.Item>
            </Stack>
          </div>
        </Modal>

        {/* Admin console /*/}
        <Modal
          isOpen={this.state.login}
          isBlocking={true}
          containerClassName={contentStyles.container}
          dragOptions={undefined}
        >
          <div className={contentStyles.header}>
            <span id="titleId">Admin Console</span>
          </div>
          <div className={contentStyles.body}>
            {/* Show the admin action dropdown menu */}
            <Dropdown label={'Actions'} onChange={this.onDropdown.bind(this)} options={[
              { text: 'Add Ticket', key: 'add-ticket'}, 
              { text: 'Update Ticket', key: 'update-ticket'},
              { text: 'Delete Ticket', key: 'delete-ticket'},
              { text: 'Add Admin', key: 'add-admin'},
              { text: 'Update Admin', key: 'update-admin'},
              { text: 'Delete Admin', key: 'delete-admin'},
            ]} />

            {/* Action: Add ticket */}
            <div id="actionAddTicket">
              <Stack tokens={itemAlignmentsStackTokens}>
                <Separator/>
                <TextField id="addTicketFirstName" label="First Name" required onChange={this.onAddTicketFirstNameInputChanged.bind(this)}/>
                <TextField id="addTicketLastName" label="Last Name" required onChange={this.onAddTicketLastNameInputChanged.bind(this)}/>
                <TextField id="addTicketUsername" label="Username" required onChange={this.onAddTicketUsernameInputChanged.bind(this)}/>
                <TextField id="addTicketGameCode" label="Game Code" required onChange={this.onAddTicketGameCodeInputChanged.bind(this)}/>
                <Dropdown label={'Game To Be Played'} onChange={this.onGameDropdown.bind(this)} options={[
              { text: 'Agency Secrets', key: 'agency-secrets'}, 
              { text: 'Game Of Clones', key: 'game-of-clones'},
            ]} />
                <TextField id="addTicketStartTime" label="Start Time" required onChange={this.onAddTicketStartTimeInputChanged.bind(this)} placeholder="YYYY-MM-DD"/>
                <TextField id="addTicketEndTime" label="End Time" required onChange={this.onAddTicketEndTimeInputChanged.bind(this)} placeholder="YYYY-MM-DD"/>
                <Stack.Item align="end">
                  <PrimaryButton text="Add Ticket" onClick={this.onClickAddTicket.bind(this)} allowDisabledFocus disabled={!this.isAddTicketFormReady.bind(this)()}>
                    <Spinner id="spinnerAddTicket" as="span" animation="border" role="status"/>
                  </PrimaryButton>
                </Stack.Item>
                <p>{this.state.outputLogText}</p>
              </Stack>
            </div>

            {/* Action: Update ticket */}
            <div id="actionUpdateTicket">
              <Stack tokens={itemAlignmentsStackTokens}>
                <Separator/>
                <TextField label="Username" required onChange={this.onUpdateTicketCurrentUsernameInputChanged.bind(this)}/>
                <TextField label="Game Code" required onChange={this.onUpdateTicketCurrentGameCodeInputChanged.bind(this)}/>
                <Separator>New Values</Separator>
                <TextField label="First Name" required onChange={this.onUpdateTicketFirstNameInputChanged.bind(this)}/>
                <TextField label="Last Name" required onChange={this.onUpdateTicketLastNameInputChanged.bind(this)}/>
                <TextField label="Username" required onChange={this.onUpdateTicketUsernameInputChanged.bind(this)}/>
                <TextField label="Game Code" required onChange={this.onUpdateTicketGameCodeInputChanged.bind(this)}/>
                <TextField label="Start Time" required onChange={this.onUpdateTicketStartTimeInputChanged.bind(this)} placeholder="YYYY-MM-DD"/>
                <TextField label="End Time" required onChange={this.onUpdateTicketEndTimeInputChanged.bind(this)} placeholder="YYYY-MM-DD"/>
                <Stack.Item align="end">
                  <PrimaryButton text="Update Ticket" onClick={this.onClickUpdateTicket.bind(this)} allowDisabledFocus disabled={!this.isUpdateTicketFormReady.bind(this)()}>
                    <Spinner id="spinnerUpdateTicket" as="span" animation="border" role="status"/>
                  </PrimaryButton>
                </Stack.Item>
                <p>{this.state.outputLogText}</p>
              </Stack>
            </div>

            {/* Action: Delete ticket */}
            <div id="actionDeleteTicket">
              <Stack tokens={itemAlignmentsStackTokens}>
                <Separator/>
                <TextField label="Username" required onChange={this.onDeleteTicketUsernameInputChanged.bind(this)}/>
                <TextField label="Game Code" required onChange={this.onDeleteTicketGameCodeInputChanged.bind(this)}/>
                <Stack.Item align="end">
                  <PrimaryButton text="Delete Ticket" onClick={this.onClickDeleteTicket.bind(this)} allowDisabledFocus disabled={!this.isDeleteTicketFormReady.bind(this)()}>
                    <Spinner id="spinnerDeleteTicket" as="span" animation="border" role="status"/>
                  </PrimaryButton>
                </Stack.Item>
                <p>{this.state.outputLogText}</p>
              </Stack>
            </div>

            {/* Action: Add admin */}
            <div id="actionAddAdmin">
              <Stack tokens={itemAlignmentsStackTokens}>
                <Separator/>
                <TextField label="Username" required onChange={this.onAddAdminUsernameInputChanged.bind(this)}/>
                <TextField label="Password" required onChange={this.onAddAdminPasswordInputChanged.bind(this)}/>
                <Stack.Item align="end">
                  <PrimaryButton text="Add Admin" onClick={this.onClickAddAdmin.bind(this)} allowDisabledFocus disabled={!this.isAddAdminFormReady.bind(this)()}>
                    <Spinner id="spinnerAddAdmin" as="span" animation="border" role="status"/>
                  </PrimaryButton>
                </Stack.Item>
                <p>{this.state.outputLogText}</p>
              </Stack>
            </div>

            {/* Action: Update admin */}
            <div id="actionUpdateAdmin">
              <Stack tokens={itemAlignmentsStackTokens}>
                <Separator/>
                <TextField label="Username" required onChange={this.onUpdateAdminCurrentUsernameInputChanged.bind(this)}/>
                <TextField label="Password" required onChange={this.onUpdateAdminCurrentPasswordInputChanged.bind(this)}/>
                <Separator>New Values</Separator>
                <TextField label="Password" required onChange={this.onUpdateAdminPasswordInputChanged.bind(this)}/>
                <Stack.Item align="end">
                  <PrimaryButton text="Update Admin" onClick={this.onClickUpdateAdmin.bind(this)} allowDisabledFocus disabled={!this.isUpdateAdminFormReady.bind(this)()}>
                    <Spinner id="spinnerUpdateAdmin" as="span" animation="border" role="status"/>
                  </PrimaryButton>
                </Stack.Item>
                <p>{this.state.outputLogText}</p>
              </Stack>
            </div>

            {/* Action: Delete admin */}
            <div id="actionDeleteAdmin">
              <Stack tokens={itemAlignmentsStackTokens}>
                <Separator/>
                <TextField label="Username" required onChange={this.onDeleteAdminUsernameInputChanged.bind(this)}/>
                <TextField label="Password" required onChange={this.onDeleteAdminPasswordInputChanged.bind(this)}/>
                <Stack.Item align="end">
                  <PrimaryButton text="Delete Admin" onClick={this.onClickDeleteAdmin.bind(this)} allowDisabledFocus disabled={!this.isDeleteAdminFormReady.bind(this)()}>
                    <Spinner id="spinnerDeleteAdmin" as="span" animation="border" role="status"/>
                  </PrimaryButton>
                </Stack.Item>
                <p>{this.state.outputLogText}</p>
              </Stack>
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    maxWidth: '500px'
  },
  ndacontainer: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch'
  },
  header: [
    // tslint:disable-next-line:deprecation
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
});
const toggleStyles = { root: { marginBottom: '20px' } };
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};

export default Admin;

