import React, { useState, useEffect } from 'react';
import Unity, { UnityContext } from "react-unity-webgl";

import { ProgressIndicator } from 'office-ui-fabric-react/lib/ProgressIndicator';
import './TevolveGame.css';
import { PixelPerfectHome } from '../PixelPerfectHome'
import { JitsiMeeting } from '@jitsi/web-sdk';

let voiceRoom = "tevolve-residential-aosuid"

class PixelPerfectGame extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      isToggleOn:false,
      progression: 0,
      loading: true,
      onWidth: '20%',
      offWidth: '0%',
      currentWidth: '20%',
      gameWidth: '80%'
    }

    this.handleClick = this.handleClick.bind(this);


    var code = this.props.gameCode
    voiceRoom += this.props.gameCode
    let gameName = this.props.gameName
    const unityContext = new UnityContext({
      loaderUrl: 'https://builds.tevolve.net/' + this.props.build + '/Build/Tevolve.loader.js',
      dataUrl: 'https://builds.tevolve.net/' + this.props.build + '/Build/Tevolve.data',
      frameworkUrl: 'https://builds.tevolve.net/' + this.props.build + '/Build/Tevolve.framework.js',
      codeUrl: 'https://builds.tevolve.net/' + this.props.build + '/Build/Tevolve.wasm',
      streamingAssetsUrl: 'https://builds.tevolve.net/' + this.props.build + '/StreamingAssets'
    });
    
    window.unityContent = unityContext
    this.unity = unityContext


    function App() {
      unityContext.on("getGameCode", function () {
        sendInfo()
      })
    
      unityContext.on("getGameName",function()
      {
        sendGameName()
      })

      return (
        <Unity unityContext={unityContext} />
      )
    }

    function sendInfo() {
      console.log("React > Sending code: " + code)
      unityContext.send("Loader", "GetGameCode", code)
    }

    function sendGameName()
    {
      console.log("React > Game Name To Send:"+gameName)
      unityContext.send("Loader", "GameName",gameName) 
    }

    unityContext.on("progress", progression => {
      this.setState({
        progression: progression
      });
    });
    App();
  }

  handleClick() {
    this.setState(prevState => ({
      isToggleOn: !prevState.isToggleOn,
      currentWidth: this.state.isToggleOn ? this.state.onWidth : this.state.offWidth,
      gameWidth: this.state.isToggleOn ? '80%' : '100%'
    }));
  }


  render() {
    return (
      <div className="game" style={{ display: 'flex', height: "100vh" }}>
        <Unity unityContext={this.unity} style={{  width: this.state.gameWidth, height: "100vh"}} />
        {
          this.state.progression === 1 ? null : <span className="loadingIndicator">
            <ProgressIndicator label="Loading game content" description={(this.state.progression * 100).toFixed(1) + "% complete..."} percentComplete={this.state.progression} />
          </span>
        }
<div id="jitsi-meeting" style={{ width:this.state.currentWidth, height: "100vh",userSelect:"none"  }}>
          <JitsiMeeting
            domain="meet.jit.si"
            roomName={voiceRoom}
            configOverwrite={{
              startWithAudioMuted: false,
              disableModeratorIndicator: true,
              startScreenSharing: false,
              enableEmailInStats: false,
              startAudioOnly: true,
              enableWelcomePage: false,
              prejoinPageEnabled: false,
              toolbarButtons:
              [
                'camera',
                'toggle-camera',
                'microphone',
                'hangup',
                'desktop'
              ]
            }}

            interfaceConfigOverwrite={{
              DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
              DISABLE_VIDEO_BACKGROUND: false,
              SHOW_CHROME_EXTENSION_BANNER: false
            }}
            userInfo={{
              displayName: this.props.voiceName
            }}
            onApiReady={(externalApi) => {
              // here you can attach custom event listeners to the Jitsi Meet External API
              // you can also store it locally to execute commands
            }}
          />
        </div>
        <button onClick={this.handleClick} style={{ alignSelf:'flex-start',justifySelf:'flex-end', backgroundColor: 'rgba(0,0,0,0);', border: 'none',userSelect:'none'}}>

<span style={{fontSize:'50px',color:'black' }}>
  {this.state.isToggleOn ? '<' : '>'}
</span>
</button>
      </div>
    )
  }
}


export default PixelPerfectGame;
